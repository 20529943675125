import { React } from 'react'
import './Skills.css'
import js from '../../Images/js.svg'
import ts from '../../Images/ts.svg'
import react from '../../Images/react.svg'
import html from '../../Images/html.svg'
import css from '../../Images/css.svg'
import github from '../../Images/github.svg'

function Skills() {
    return(
            <div className='Sezione'>
                <div className='Bullets'>
                        <ul>
                            <li style={{marginLeft: 0}}> JavaScript - Typescript (OOP, ES6) </li>
                            <li> HTML/CSS </li>
                            <li> React </li>
                            <li> React Native </li>
                        </ul>
                        <ul>
                            <li> AWS </li>
                            <li> GitHub </li>
                            <li> Copywriting </li>
                            <li> Padronanza Italiano/Inglese </li>
                        </ul>
                </div>
                <div className='Immagini'>
                    <div className='Set'>
                    <img className='Logo' src={js}/>
                    <img className='Logo' src={ts}/>
                    <img className='Logo' src={react}/>
                    </div>
                    <div className='Set'> 
                    <img className='Logo' src={html}/>
                    <img className='Logo' src={css}/>
                    <img className='Logo' src={github}/>
                    </div>
                </div>
            </div>
    );
}

export default Skills;