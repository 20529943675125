import { React } from 'react'
import { Link } from 'react-router-dom';
import './NavBar.css'
import Bottone from './Bottone';
function NavBar() {
    return(
        <div className='NavBar'>
            <div className="Titolo">Damiano Fiorini</div>
            <div className='NavButtons'>
                <Link to='/'>
                    <Bottone titolo="Home"/>
                </Link>
                <Link to='/skills'>
                    <Bottone titolo="Skills"/>
                </Link>
                <Link to='/formazione'>
                    <Bottone titolo="Formazione"/>
                </Link>
                <Link to='/esperienza'>
                    <Bottone titolo="Esperienza"/>
                </Link>
                <Link to='/contattami'>
                    <Bottone titolo="Contattami"/>
                </Link>
            </div>
        </div>
    );
}

export default NavBar