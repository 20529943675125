import { React } from 'react';
import Galilei from '../../Images/galilei.png';
import Unipi from '../../Images/unipi.png';
import './Formazione.css'

function Formazione() {
    return(
            <div className='Body'>
                <div className='Card1'>
                    <img className='Bandiera' src={Galilei}/>
                    <text style={{fontSize: '50%'}}> ITI Galileo Galilei <br/></text>
                    <text style={{fontSize: '20%', alignSelf: 'center'}}>📍 Viareggio - 06/2021</text>
                    <p className='Paragrafo'>
                        Diploma conseguito con specializzazione in informatica. <br/>
                        Perito informatico, studi specifici nello sviluppo web e nelle architetture di sistema 
                        trattando temi da database/query, fino all’implementazione web con JavaScript e HTML.
                        Creazione autonoma di semplici progetti web,
                        supportati dall'implementazione di back-end SQL e MySQL DBMS. 
                    </p>
                </div>
               <text style={{marginRight: '-5vh'}}> - - - -</text>
                <div className='Card2'>
                    <img className='Bandiera' src={Unipi}/>
                    <text style={{fontSize: '50%'}}> Università di Pisa <br/></text>
                    <text style={{fontSize: '20%', alignSelf: 'center'}}>📍 Pisa - In corso</text>
                    <p className='Paragrafo'>
                    Corso triennale in informatica. <br/>
                    Analisi dei paradigmi di programmazione, dei fondamenti dell’informatica e delle basi del calcolo.
                    Studio dei principali linguaggi di programmazione e delle architetture dei sistemi operativi.
                    Specializzazioni in cloud computing, mobile development ed intelligenza artificiale.
                    </p>
                </div>
            </div>
    );
}

export default Formazione;