import { React } from 'react'
import Damiano from '../../Images/Damiano.JPG'
import Linkedin from '../../Images/linkedin.png'
import GitHub from '../../Images/github.png'
import './Home.css';
function Home() {
    return(
        <div>
            <div className='Body'>
              <div>
                <p style={{marginBottom: '0'}}>
                Ciao! Sono Damiano, <br/>
                Front-End Developer.
                </p>
                <div className='GruppoIcone'>
                  <a href='https://www.linkedin.com/in/damianofiorini/'>
                  <img className="icon" style={{width: '7vh', borderRadius: '50%'}} src={Linkedin}/>
                  </a>
                  <a href='https://github.com/DamisFloris'>
                  <img className="icon" style={{width: '7vh', borderRadius: '50%', marginLeft: '2%'}} src={GitHub}/>
                  </a>
                </div>
            </div>
                <img className='Foto' src={Damiano}/>
            </div>
        </div>
    );
}

export default Home;