import { React } from 'react'
import './Esperienza.css'
function Esperienza() {
    return(
        <div>
            <div className='JobBody'>
                <div className='Row1'>
                    <div className='JobCard'>
                        <div className='Titoli'>
                            <text style={{fontSize: '110%', fontWeight:'bold'}}>Technical Co-Founder <label style={{fontWeight:'100'}}>at</label> Radar Technologies Ltd.</text>
                            <text style={{fontWeight: '600', fontSize:'60%'}}>📍 London - In corso</text>
                        </div>
                        <ul className='ElencoLavori'>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Ricerca del prodotto ed ideazione attraverso metodologia design thinking.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Creazione di applicazioni mobile da zero utilizzando React Native.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Integrazione dei servizi di cloud computing AWS e API.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Rispetto totale delle scadenze, proponendo costantemente nuove funzionalità in vista dei futuri aggiornamenti. </li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Produzione di codice front-end e gestione attraverso librerie GitHub dedicate.</li>
                            <li style={{fontSize:'80%'}}>Tech Stack: React Native, React, TypeScript, AWS (Amplify, SES, AppSync), Figma.</li>
                        </ul>
                    </div>

                    <div className='JobCard'>
                        <div className='Titoli'>
                            <text style={{fontSize: '110%', fontWeight:'bold'}}>Addetto Vendite <label style={{fontWeight:'100'}}>at</label> MediaMarket S.p.A.</text>
                            <text style={{fontWeight: '600', fontSize:'60%'}}>📍 Lucca - 01/2023</text>
                        </div>
                        <ul className='ElencoLavori'>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Figura responsabile delle vendite hardware e software nel reparto computer.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Approccio al cliente con attenta analisi delle necessità al fine di proporre il prodotto ideale alle esigenze.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Integrazione di conoscenze pregresse in campo informatico, principalmente hardware.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Utilizzo frequente di portali informatici gestionali ai fini di interrogare il magazzino.<br/></li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Produzione di codice front-end e gestione attraverso librerie GitHub dedicate.</li>
                            <li style={{fontSize:'80%'}}>Accoglienza dei clienti bisognosi di assistenza e guida nella risoluzione dei  problemi posti.</li>
                        </ul>
                    </div>

                </div>

                <div className='Row2'>
                    <div className='JobCard'>
                        <div className='Titoli'>
                            <text style={{fontSize: '110%', fontWeight:'bold'}}>Front-End Developer <label style={{fontWeight:'100'}}>at</label> FoodsPass Inc.</text>
                            <text style={{fontWeight: '600', fontSize:'60%'}}>📍 Los Angeles / Remote - 02/2022</text>
                        </div>
                        <ul className='ElencoLavori'>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Produzione di codice efficiente e scalabile, capace di integrarsi alle necessità dell’applicazione; sviluppo di interfacce responsive front-end mobile sottostando alle linee guida di design e all’architettura di informazione con React e React Native.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Lavoro a stretto contatto con il CTO ed il team UX/UI di design, al fine di migliorare l’usabilità e l’esperienza finale di utilizzo.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Aumento dell’efficienza gestionale dell’applicazione, con miglioramenti nei tempi di inserimento dati, riducendoli del 50%.</li>
                            <li style={{fontSize:'80%'}}>Tech Stack: React Native, React, GitHub, Figma. </li>
                        </ul>
                    </div>

                    <div className='JobCard'>
                        <div className='Titoli'>
                            <text style={{fontSize: '110%', fontWeight:'bold'}}>Aiuto Operaio <label style={{fontWeight:'100'}}>at</label> Fiorinimpianti Srl.</text>
                            <text style={{fontWeight: '600', fontSize:'60%'}}>📍 Viareggio - 08/2021</text>
                        </div>
                        <ul className='ElencoLavori'>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Assistenza sul posto al team di lavoro, operando a stretto contatto con impiantistica elettrica ed idraulica.</li>
                            <li style={{marginBottom: '2%', fontSize:'80%'}}>Manutenzione del verde pubblico lavorando su impianti di irrigazione in varie zone della Versilia.</li>
                            <li style={{fontSize:'80%'}}>Riordino e stoccaggio della merce all’interno del magazzino aziendale.</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Esperienza;