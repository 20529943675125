import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import abstract from './Images/abstract.jpg'
import Home from './Screens/Home/Home';
import NavBar from './NavBar';
import Skills from './Screens/Skills/Skills';
import Formazione from './Screens/Formazione/Formazione'
import Esperienza from './Screens/Esperienza/Esperienza';

function App() {
  return (
    <Router>
      <div className="App"
      style={{backgroundImage: `url(${abstract})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
     }}>
      <NavBar/>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/skills' element={<Skills/>}/>
        <Route exact path='/formazione' element={<Formazione/>}/>
        <Route exact path='/esperienza' element={<Esperienza/>}/>
      </Routes>
      </div>
    </Router>
  );
}

export default App;
